$border-color: #ccc;
@media (max-width: 1460px) {
  .contain {
    max-width: 100% !important;
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  height: 100%;
}

#root {
  height: 100%;
}

ul {
  list-style-type: none;
}

a {
  outline: none;
}

fieldset {
  background-color: #f2f2f2;
  margin: 0 0 12px 0 !important; //serial inquiry beta change
  font-size: 12px;
}

// legend.scheduler-border {font-size: 11px!important;font-weight: normal !important;text-align: left !important;width:auto;padding:0 10px;border-bottom:none;}
fieldset legend {
  font-weight: 500;
  margin-left: 12px;
}

div .k-textbox,
div .k-dropdown-wrap,
div fieldset {
  border: 1px solid $border-color;
  padding-left: 0px !important;
}

fieldset .k-textbox {
  height: 24px;
}

.content {
  background-color: #fff;
}

.k-grid th,
.k-grid td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.k-grid td {
  font-size: 12px !important;
}

//.containerbx fieldset{padding-bottom: 0!important;}
.containerbx fieldset .borderNone {
  border: none !important;
}

.k-button {
  font-size: 12px !important;
}

.expand-h-full {
  position: relative;
}

.expand-h-full .toggle-menu {
  position: absolute;
  right: 0;
  display: none;
}

.nav li {
  position: relative;
}

.active-nav {
  font-weight: bold;
  position: relative;
}

.active-nav:after {
  content: "";
  border-bottom: 2px solid #f90;
  position: absolute;
  bottom: -7px;
  width: 100%;
  right: 0;
}

::placeholder {
  font-size: 12px !important;
}

.k-switch-handle {
  top: -0.45em;
}

footer p,
footer span {
  font-size: 12px;
}

.height-full {
  min-height: calc(100vh - 55px);
}

.stopdeliveryclass {
  background: red !important;
}

.k-notification-group {
  position: absolute !important;
  right: 0;
  bottom: 0;
}

.font-small {
  font-size: 12px;
}

.containerbx .k-textbox,
.containerbx .k-input {
  padding: 0px 0px 7px 12px !important;
  font-size: 12px;
}

textarea.k-textarea {
  padding: 5px 0px 5px 12px !important;
  font-size: 13px !important;
  border: none !important;
  resize: none !important;
  width: 100% !important;
}

.k-combobox .k-dropdown-wrap .k-input,
.inputBox .k-input {
  padding: 9px 0px 7px 12px !important;
  height: auto !important;
}

.k-checkbox-label {
  font-weight: 500;
}

.k-icon.k-i-warning::before,
.k-icon.k-i-exception::before {
  content: "\26A0";
  font-weight: bold;
}

.borderNone .k-dropdown-wrap {
  border: none !important;
}

/*401 and 404*/
#notfound {
  position: relative;
  height: calc(100vh - 27px);
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.notfound .notfoundBox {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  background-image: url('./images/emoji.png');
  contain: '\2639';
  background-size: cover;
}

.notfound .notfoundBox:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 50%;
  background-color: #f2f5f8;
  z-index: -1;
}

.notfound h1 {
  font-family: 'Nunito', sans-serif;
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}

.notfound h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #151723;
}

.notfound p {
  font-family: 'Nunito', sans-serif;
  color: #999fa5;
  font-weight: 400;
}

.notfound a {
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: #388dbc;
}

ul.packaging li {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  margin-right: 1rem;
  width: auto;
}

@media (min-width: 992px) {
  ul.packaging li {
    width: 55px;
  }
}

@media (min-width: 1024px) {
  ul.packaging li {
    width: 60px;
  }
}

@media (min-width: 1280px) {
  ul.packaging li {
    width: 90px;
  }
}

@media (min-width: 1480px) {
  ul.packaging li {
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .notfound .notfoundBox {
    width: 110px;
    height: 110px;
  }

  .notfound {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
}

// .spinner {
//   margin: 100px auto 0;
//   width: 70px;
//   text-align: center;
// }

// .spinner > div {
//   width: 18px;
//   height: 18px;
//   background-color: #333;

//   border-radius: 100%;
//   display: inline-block;
//   -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
//   animation: sk-bouncedelay 1.4s infinite ease-in-out both;
// }

// .spinner .bounce1 {
//   -webkit-animation-delay: -0.32s;
//   animation-delay: -0.32s;
// }

// .spinner .bounce2 {
//   -webkit-animation-delay: -0.16s;
//   animation-delay: -0.16s;
// }

// @-webkit-keyframes sk-bouncedelay {
//   0%, 80%, 100% { -webkit-transform: scale(0) }
//   40% { -webkit-transform: scale(1.0) }
// }

// @keyframes sk-bouncedelay {
//   0%, 80%, 100% { 
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   } 40% { 
//     -webkit-transform: scale(1.0);
//     transform: scale(1.0);
//   }
// }
.k-grid-footer {
  border-top: 0 !important;
}

.k-grid-footer td {
  border-bottom: 0 !important;
}

.k-grid-footer tr {
  background: transparent !important;
}

// For file button
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Import Serial Data (Excel)';
  display: inline-block;
  background: #01579b;
  border: 1px solid #01579b;
  padding: 5px 12px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: #fff;
  width: 100%;
  text-align: center;
}

.custom-file-input.continue-text::before {
  content: 'CONTINUE';
  height: 100%;
  padding: 7px 12px;
}

.custom-file-input.importPartBtn::before {
  content: 'Import part change data';
}

.custom-file-input.importCustomerPartBtn::before {
  content: 'Import customer part change data';
}

.custom-file-input.importSerialExcelBtn::before {
  content: 'Import Serial Numbers (Excel)';
}

.custom-file-input:hover::before {
  border-color: #01579b;
}

.custom-file-input:active::before {
  background: #01579b;
}

.defaultselect .k-dropdown .k-dropdown-wrap .k-input {
  padding: 8px;
  font-weight: 500;
  font-size: 14px !important;
}

.flexFieldsDD .k-input {
  height: 24px !important;
  padding-bottom: 0px !important;
}

.flexFieldsDD .k-select {
  padding-bottom: 0px !important;
}

.flexFieldPopup .k-dialog-wrapper {
  z-index: 99 !important;
}

.notification-container .k-notification-container {
  width: 100%;
}

div .k-notification-wrap>.k-icon {
  margin-top: 0;
  margin-left: 4px;
}

.notification-container .k-notification-info {
  background-color: #137bc41a;
  color: #137bc4;
  width: 100%;
  padding: 0;
  padding-left: 3px;
  border-radius: 0;
  font-weight: 500;
  padding: 4px 0;
  display: flex;
  align-items: center;
}

.notification-container .k-notification-warning {
  color: #df8403;
  background-color: #ffee0047;
  width: 100%;
  padding: 0;
  padding-left: 3px;
  border-radius: 0;
  font-weight: 500;
  padding: 4px 0;
  display: flex;
  align-items: center;
}

.k-dialog-wrapper .k-dialog-titlebar {
  border-radius: 0;
  background-color: #fff;
  color: #000;
}

.k-dialog-wrapper .k-dialog-button-layout-stretched {
  border-radius: 0;
  background-color: #fff;
  border-top: 1px solid #ccc;
  color: #000;
}

.k-dialog-wrapper .k-dialog-action {
  display: none;
}

.k-dialog-wrapper .k-content ul {
  margin: 0;
  padding: 0;
  min-width: 300px;
  text-align: center;
}

.settings .k-panelbar>.k-item>.k-link.k-state-selected {
  color: #000
}

.border-grey-swith {
  border-color: #ccc;
}

.custom-alert-wrapper .k-notification-container {
  margin: 0px;
}

.custom-alert-wrapper .k-notification-content {
  font-weight: 400;
}

.delivery-details-grid .k-input,
.delivery-details-grid .k-dropdown .k-dropdown-wrap .k-select,
.k-dropdowntree .k-dropdown-wrap .k-select {
  padding: 0;
  font-size: 12px;
  padding-top: 0px !important;
}

.delivery-details-grid .k-dropdown {
  margin: 3px 0px;
  border: none !important;
}

.delivery-details-grid .k-dropdown .k-dropdown-wrap {
  height: 20px;
}

.mt--3 {
  margin-top: -3px;
}

.disabled-input-height {
  height: 24px;
}

.disabled-input-height-double-row {
  height: 44px;
}

.rma-inq .disabled-input-height {
  margin-top: 8px;
}

.warrantyInq .k-dropdown,
.k-dropdowntree {
  height: 27px;
}

.k-list .k-item,
.k-list-optionlabel {
  padding: 5px !important;
  /* min-height: 1.5em; */
  line-height: normal !important;
  white-space: normal !important;
  transition-property: color, background-color, outline-color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease;
}

div.k-window-title {
  padding-top: 12px;
}

.upsModal.k-dialog-wrapper {
  z-index: 99;
}

.k-dialog-buttongroup .k-button,
.k-dialog-buttongroup .k-button-secondary {
  color: #01579b;
}

div .k-dialog-buttongroup .k-button-primary,
div .k-dialog-buttongroup .k-button.k-primary {
  background-color: #ebebeb;
  color: #01579b;
}

.k-dialog-wrapper .k-dialog-button-layout-stretched {
  margin-top: 16px;
}

.k-dialog-wrapper .k-dialog {
  overflow-y: auto;
}

.animationNone.k-i-loading::before,
.animationNone.k-i-loading::after {
  animation: none;
}

fieldset:disabled .k-textbox:disabled,
fieldset:disabled .k-textbox[disabled],
fieldset:disabled .k-textbox.k-state-disabled,
fieldset:disabled .k-dropdown-wrap {
  opacity: 0;
}

input.editableInput:disabled {
  background-color: #f2f2f2 !important;
  opacity: 0.5 !important;
}


.filterable .k-filtercell-wrapper .k-filtercell-operator span.k-widget.k-dropdown {
  display: none;
}

.filterable .k-filtercell-wrapper .k-filtercell-operator .k-button.k-button-icon {
  padding: 0px;
}

.filterable .k-filtercell-wrapper .k-textbox {
  padding: 0;
}

.filterable .k-filtercell-operator .k-button.k-button-icon {
  width: auto;
  height: auto;
}

.filterable .k-filtercell-wrapper .k-filtercell-operator {
  margin: 0;
  line-height: 22px;
}

.filterable .k-filtercell .k-filtercell-wrapper>.k-textbox {
  height: 22px;
  font-size: 13px;
}

.filterable .k-filtercell .k-filtercell-operator>.k-button:not(.k-clear-button-visible) {
  height: auto;
  visibility: visible;
  opacity: .2;
}

.filterable .k-filtercell .k-filtercell-operator>.k-button.k-clear-button-visible {
  height: auto;
  background: transparent;
}

.filterable .k-filtercell .k-filtercell-operator>.k-button .k-icon.k-i-filter-clear {
  font-size: 13px;
}

.grid-footer .k-grid-footer-wrap tfoot {
  background: #f2f3f3;
}

.grid-footer .k-grid-footer-wrap {
  position: relative;
  top: 1px;
  left: -1px;
  margin-right: -2px;
  border: 0;
  border-top: 1px solid #e4e4e4;
}

.full-table .k-grid td {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.full-table .k-grid.grid-sm-space td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.full-table .k-grid th {
  overflow: visible !important;
  text-overflow: unset;
  width: auto;
}

.full-table .k-grid-header .k-header>.k-link {
  overflow: visible !important;
  text-overflow: unset;
  width: auto;
  display: block;
}

.full-table div.k-grid-header,
div.k-grid-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.full-table .cancelReqResponseMenu {
  padding: 0;
  margin: 0;
  width: 0;
}

.full-table .k-button.k-button-icon.k-button:hover::before {
  opacity: 0;
}

.full-table .k-grid table tr:hover .k-button.k-button-icon.k-button {
  background-color: #ededed;
}

.full-table .k-grid table tr .k-button.k-button-icon.k-button:active {
  background-color: #fff;
}

.chartView svg {
  overflow: visible !important;
}

.chartView text {
  font-size: 10px !important;
}

.k-button.k-primary.s-btn {
  font-size: 11px !important;
  padding: 4px 10px;
}

// .eventLogTable table colgroup col:first-child{
//   width: 80px !important;
// }
// .eventLogTable .k-grid .k-hierarchy-cell .k-icon{
//   font-size: 11px;
//   color: #01579b;
// }
// .eventLogTable .k-i-minus::after, .k-minus::after{
//   content: "view payload";
//   font-family: 'Roboto';
//   text-transform: capitalize;
// }
// .eventLogTable .k-i-plus::before, .k-plus::after{
//   content: "view payload";
//   font-family: 'Roboto';
//   text-transform: capitalize;
// }
.k-grid .k-hierarchy-cell .k-icon.text-blue {
  color: inherit;
}

.viewDetailTable div.k-grid-header {
  display: none;
}

.ViewDetailInlinetable table {
  table-layout: fixed;
}

.ViewDetailInlinetable {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}

.ViewDetailInlinetable th,
.ViewDetailInlinetable td {
  line-height: 1.5;
  padding: 4px 24px 4px 24px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  ;
  border-width: 0 0 1px 1px;
  vertical-align: middle;
  font-size: 12px;
}

.ViewDetailInlinetable tr:last-child>td {
  border-bottom-width: 0;
}

.containerbx .datePickInfo input {
  padding: 0 3px !important;
  line-height: 0 !important;
  height: auto !important;
}

.k-master-row .k-grid-content-sticky {
  background-color: inherit !important
}

.k-grid-header th.k-header,
.k-grid-header th.k-grid-header-sticky,
.k-grid-header .k-grid-header-sticky.k-sorted {
  background-color: #f8f9fa !important;
}

.k-grid table tr:hover,
.k-grid table tr.k-state-hover,
.k-grid table td.k-state-focused,
.k-grid table th.k-state-focused {
  background: #ededed !important;
}

.k-grid table tr {
  background: #fff
}

.k-grid table tr.k-alt {
  background: #f3f3f3
}

.k-grid .cancelReqResponseMenu .k-button,
.k-grid .cancelReqResponseMenu .k-button:active {
  background-color: inherit !important
}

.ddDatePicker .w-26 {
  width: 80px;
}

.ddDatePicker .k-select.k-select {
  display: none;
}

.hideEnterBtn .enter-icon {
  visibility: hidden;
}

.opacity70 {
  opacity: .70;
}

.w-datePik {
  width: 115px;
}

.w-datePik input {
  text-transform: capitalize;
}

.dataBoxView.activeBox {
  box-shadow: 0 0 6px rgba(0, 0, 0, .40);
  border-color: green;
  border-width: 1px;
  background-color: #f7f7f7;
}

.k-icon.text-xxs {
  font-size: .75rem;
}

.k-sparkline span {
  width: 100% !important;
  border: 1px solid #ececec;
}

.k-widget.k-datepicker.w-full {
  width: 100%
}

.max-w-132 {
  max-width: 132px;
}

.wd-loading {
  width: 100%;
  height: 100%;
  z-index: 100;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: #ecececc9
}

.wd-table-loading {
  width: 100%;
  height: 100%;
  z-index: 100;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: #ececec4f;
  display: flex;
  padding-bottom: 60px;
}

.PopupDialog .k-dialog-wrapper {
  z-index: 99 !important;
}

.groupingGrid .k-detail-cell {
  padding: 0;
}

.groupingGrid .k-detail-cell .k-widget {
  margin: 15px 0;
}

.w-full-autocomplete .k-autocomplete {
  width: 100%;
}

.custom-file-input.importEBookingTemplate::before {
  content: 'Upload e-booking Template(Excel)';
}

.slick-slider .slick-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  background: none;
  width: 15px;
  height: 15px;
  top: 35%;
  margin: 0px 15px;
}

.slick-slider .slick-next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 10px;
}

.slick-slider .slick-prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 10px;
}

.internalmodel .k-dialog-wrapper {
  z-index: 101 !important;
}

.myprofile-shipping .k-animation-container {
  z-index: 99;
}

.user-admin .k-dialog-wrapper,
.my-profile-section .k-dialog-wrapper {
  z-index: 110 !important
}

.myprofile-shipping .k-animation-container {
  z-index: 99;
}

.btn-group button {
  font-size: 25px;
  padding: 10px 24px;
  /* Some padding */
  cursor: pointer;
  /* Pointer/hand icon */
  width: 50%;
  /* Set a width if needed */
  display: block;
  /* Make the buttons appear below each other */
}

.btn-group button:not(:last-child) {
  border-bottom: none;
  /* Prevent double borders */
}

/* Add a bottom border for selected button*/
.btn-selected {
  border-bottom: 2px solid #f90 !important;
}